import { defineStore } from 'pinia';
import { ref, Ref } from 'vue';
import { MeDocument, IMeQuery, cleanGraphqlObj, IUser } from '@bounsel/sdk';
import { useApolloClient } from '@vue/apollo-composable';

export const useUserStore = defineStore('user', () => {
  const currentUser = ref<IUser | undefined>();
  const { resolveClient } = useApolloClient();
  const isLoggedIn = ref<boolean>(false);
  const app = useNuxtApp();

  const check = async () => {
    const client = resolveClient();
    return client
      .query({
        query: MeDocument,
      })
      .then(async (result) => {
        const data: IMeQuery = result?.data;

        if (data && data.me) {
          const user = cleanGraphqlObj(data.me);
          isLoggedIn.value = true;

          // if (user.language) {
          //   i18n.global.locale.value = user.language as string;
          //   api.config.setLang(user.language);
          //   (
          //     this as any
          //   ).$app.config.globalProperties.$luxon.Settings.defaultLocale =
          //     i18n.global.locale.value;
          // }

          // await context.dispatch('lastActiveAccount');
          app.$analytics?.identify(user);
          currentUser.value = user;
          // (this as any).$app.config.globalProperties.$feedback.identify(
          //   user?.feedbackId
          // );
          // (this as any).$app.config.globalProperties.$monitoring.identify(user);
          return user;
        }

        return null;
      })
      .finally(() => {
        // context.commit(types.UPDATE_LOADING, false);
      });
  };

  return {
    user: currentUser,
    isLoggedIn: isLoggedIn as Ref<boolean>,
    check,
  };
});
