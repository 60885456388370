import cloneDeepWith from 'lodash/cloneDeepWith';
import isUndefined from 'lodash/isUndefined';
import unset from 'lodash/unset';
const omitDeep = (input, excludes) => {
    const omit = [];
    const clone = cloneDeepWith(input, (_value, key, object, stack) => {
        if (!isUndefined(key) && excludes.includes(key)) {
            omit.push({ key, from: stack.get(object) });
            return null;
        }
    });
    omit.forEach(({ key, from }) => unset(from, key));
    return clone;
};
export const cleanGraphqlObj = (obj) => {
    return omitDeep(obj, ['__typename']);
};
export const extractGQLErrorMessage = (error) => {
    const { 1: errorMessage } = error.message.split('GraphQL error: ');
    return errorMessage;
};
export const gqlError = (error, altMessage) => {
    return altMessage || extractGQLErrorMessage(error);
};
export const formatGQLError = (error) => {
    const e = error.gqlError || error.graphQLErrors[0];
    if (!e) {
        return undefined;
    }
    return {
        code: e.code,
        type: e.type,
        message: e.message || error.message,
    };
};
